/**
 *
 * This page container contains the main tag and transition logic. It should
 * wrap all pages in their respective template files. If used in DefaultLayout,
 * it cannot access Transition State.
 *
 */

import React from "react";
import { useAppState } from "@state";
import useLakotaAcFooterData from "@staticQueries/lakotaAc/FooterQuery";
import useLakotaFooterData from "@staticQueries/lakota/FooterQuery";
import classNames from "classnames";
import filterActions from "@utils/filterActions";
import { AnimatePresence, m } from "framer-motion";
import loadable from "@loadable/component";

const LakotaFooter = loadable(() => import("@organisms/LakotaFooter"));
const Cta = loadable(() => import("@organisms/Cta"));

const PageContainer = ({ children, className, location, hideCta }) => {
  const { pathname: path } = location;
  const [{ layout, ea }] = useAppState();
  const { footerNav, actions } =
    process.env.GATSBY_CRAFT_SITE_HANDLE === "lakotaAc"
      ? // eslint-disable-next-line react-hooks/rules-of-hooks
        useLakotaAcFooterData()
      : // eslint-disable-next-line react-hooks/rules-of-hooks
        useLakotaFooterData();

  const upNext = filterActions(actions, ea.activistCodes || []);

  const action = upNext.length >= 1 ? upNext[0] : actions[actions.length - 1];
  const { theme, template } = layout;
  const isAction = template === "action";

  return (
    <m.div
      key={path}
      id="ac-layout"
      initial={{ opacity: 0, y: "10rem" }}
      animate={{ opacity: 1, y: "0rem" }}
      exit={{ opacity: 0, y: "-10rem" }}
      transition={{ duration: 0.5 }}
      className={classNames(
        "relative z-0 flex min-h-screen w-full flex-grow flex-col",
        className,
        {
          "bg-cream": theme === "light",
          "bg-brown": theme !== "light",
        }
      )}
    >
      <AnimatePresence initial>
        <main key="main" className="flex-grow">
          {children}
        </main>
        <footer key="footer">
          {!isAction && action && !hideCta && (
            <div>
              <Cta
                heading={action.heading}
                copy={action.copy}
                icon="buffalo"
                backgroundImage={action.backgroundImage}
                action={action}
                showAction
                path={path}
              />
            </div>
          )}
          <LakotaFooter nav={footerNav} />
        </footer>
      </AnimatePresence>
    </m.div>
  );
};

PageContainer.defaultProps = {
  className: "",
};

export default PageContainer;
